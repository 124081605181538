body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

* {
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}